<template>
  <div class="d-flex justify-content-between h-100">
    <div class="d-block w-100 h-100">
      <Logo :data="data" />
    </div>
    <div class="d-block w-100 h-100">
      <Favicon :data="data" />
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import Logo from './component/logo.vue';
import Favicon from './component/favicon.vue';

export default defineComponent({
  components: {
    Logo,
    Favicon,
  },
  setup(_, context) {
    const expo = {};
    expo.data = computed(() => store.getters.getLogoFavicon);
    store.dispatch('logoFaviconGetir');
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
