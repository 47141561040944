<template>
  <div>
    <div class="border-bottom d-flex justify-content-between">
      <h5 class="m-4">Favicon</h5>
      <div class="mr-3">
        <input
          type="file"
          ref="favicon"
          name="favicon"
          id="selectorImagesFavInput"
          accept="image/jpg,image/jpeg,image/png,image/gif"
          class="selector-images-input"
          @change="handlerOnFavSumbited"
        />
        <div class="selector-images-button" :disabled="true" @click="handlerSelectedFavImages">
          <i class="fad fa-images fa-2x" v-if="!show" />
          <b-spinner v-else />
        </div>
      </div>
    </div>
    <div class="p-3 logo-images" v-if="data.favicon">
      <span class="logo-image-icon" @click="handlerRemoveFavImages(data.k_no)">
        <i class="fad fa-trash fa-2x"></i>
      </span>
      <b-img thumbnail class="rounded-0 w-50" :src="handlerFaviconShow(data.favicon)"></b-img>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';

export default defineComponent({
  props: {
    data: {
      type: Object,
      required: false,
    },
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    const fileName = store.getters.getUserFirma.db;

    expo.handlerSelectedFavImages = () => {
      const file = document.getElementById('selectorImagesFavInput');
      file.click();
    };

    expo.handlerFaviconShow = computed(() => {
      return (val) => {
        if (val != undefined) {
          const base_url =
            process.env.NODE_ENV === 'development'
              ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
              : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

          return `${base_url}uploads/${fileName}/favicon/${val}`;
        }
      };
    });

    expo.handlerOnFavSumbited = async () => {
      expo.show.value = true;
      let formData = new FormData();
      let forms = context.refs.favicon.files;
      Object.keys(forms).map(function (key) {
        formData.append('favicon', forms[key]);
      });

      // formData.forEach((val) => {
      //
      // });

      await store
        .dispatch('faviconEkle', formData)
        .then((res) => {
          expo.show.value = false;
        })
        .catch((err) => {
          console.log(err);
          expo.show.value = false;
        });
    };

    expo.handlerRemoveFavImages = async (k_no) => {
      await store.dispatch('faviconSil', k_no).then((res) => {
        if (res.data.success == true) {
          toast.success('Silme Başarılı', { position: 'bottom-left' });
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.selector-images-input {
  display: none;
}
.selector-images-button {
  position: relative;
  display: flex;
  width: 100%;
  flex-grow: 1;
  // max-width: 250px;
  height: auto;
  max-height: 100px;
  background-color: #ddd;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 0px;
  cursor: pointer;
  &:hover {
    background-color: darken(#dee, 9);
  }
}
.logo-images {
  position: relative;
  .logo-image-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    background: rgba(191, 191, 191, 0.597);
    padding: 10px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  &:hover {
    .logo-image-icon {
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }
  }
}
</style>
